<template>
    <v-container fluid>
        <v-row row wrap>
            <v-col cols="12" class="text-center" mt-5>
                <h1>Welcome!</h1>
            </v-col>
            <v-col center cols="12">
                <SquareReceipt
                    v-bind:order="order"
                    v-bind:showTitle="false"
                    v-bind:showDetails="true"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import SquareReceipt from '@/components/payment/SquareReceipt'
export default {
    computed: {
        order () {
            return this.$store.state.order
        }
    },
    components: {
        SquareReceipt
    }
}
</script>